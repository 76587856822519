<template>
  <div class="healthHouse-my">
    <div class="healthHouse-my-t"></div>
    <div class="healthHouse-my-c">
      <div class="wxImgimg">
        <img :src="user.img ? user.img : require('./img/wxImg.png')" alt="" />
      </div>
      <div class="healthHouse-my-ct">
        <div class="name">
          {{ user.name ? user.name : "微信用户" }}的检测报告
        </div>
        <div class="btn" @click="toMyInfo">个人信息</div>
      </div>
      <div class="healthHouse-my-cb">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="item"
            v-for="(item, index) in oList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="time">{{ item.createTime }}</div>
            <div class="name">{{ item.hutName }}</div>
            <div class="icon"><img src="./img/icon.png" alt="" /></div>
          </div>
        </v-list>
      </div>
    </div>
    <div class="healthHouse-my-b"></div>
  </div>
</template>

<script>
import { getExamListUrl } from "./api";
import { mapState } from "vuex";
import { getHashParam } from "@/utils/utils.js";
export default {
  name: "healthHouse-my",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      user: {
        img: "",
        name: "",
        userId: "",
      },
      oList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.user.img = this.userInfo.avatarUrl;
    this.user.name = this.userInfo.nickName;
    this.user.userId = this.userInfo.id;
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "healthReport",
        query: {
          examId: item.examId,
        },
      });
    },
    async getExamList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.user.userId,
      };
      let res = await this.$axios.post(`${getExamListUrl}`, params);
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          this.oList.push(item);
        });
      } else {
      }
    },
    onLoad() {
      this.getExamList();
    },
    toMyInfo() {
      this.$router.push({
        name: "healthHouse-myInfo",
      });
    },
  },
};
</script>

<style scoped lang="less">
.healthHouse-my {
  min-height: 100%;
  background: rgba(239, 247, 248, 1);
  padding: 60px 26px;
  box-sizing: border-box;
  position: relative;
  .healthHouse-my-t {
    height: 212px;
    background: url("./img/topBack.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  .healthHouse-my-c {
    background: #ffffff;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    min-height: 100%;
    padding: 0 26px;
    .wxImgimg {
      width: 108px;
      height: 108px;
      position: absolute;
      top: -22px;
      left: 22px;
      border-radius: 50%;
      z-index: 6;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .healthHouse-my-ct {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0 46px 140px;
      border-bottom: 2px solid #f1f1f1;
      .name {
        width: 300px;
        font-size: 30px;
        font-weight: 500;
        color: #121a2d;
        line-height: 42px;
      }
      .btn {
        width: 150px;
        height: 54px;
        text-align: center;
        border-radius: 6px;
        border: 2px solid #089bab;
        font-size: 26px;
        font-weight: 400;
        color: #089bab;
        line-height: 54px;
      }
    }
    .healthHouse-my-cb {
      padding-top: 30px;
      .item {
        height: 170px;
        background: #eff7f8;
        border-radius: 10px;
        padding: 34px 0 0 40px;
        box-sizing: border-box;
        margin-bottom: 20px;
        position: relative;
        .time {
          font-size: 26px;
          font-weight: 400;
          color: #089bab;
          line-height: 36px;
          margin-bottom: 22px;
        }
        .name {
          width: 400px;
          font-size: 30px;
          font-weight: 700;
          color: #089bab;
          line-height: 38px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .icon {
          width: 108px;
          height: 108px;
          position: absolute;
          bottom: 18px;
          right: 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .healthHouse-my-b {
    height: 212px;
    background: url("./img/bottomBack.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
}
</style>
